// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Raffleticket__main {
  display: flex;
  flex-direction: column;
  background-color: #000000;
  padding-top: 50px;
  height: 100vh;
}
.Raffleticket__tableheadtext {
  border-bottom: 1px solid #121212 !important;
  font-family: Montserrat !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  letter-spacing: 0.10000000149011612px !important;
  text-align: left !important;
  color: #ffffff80 !important;
  padding: 0px 20px 20px 0px !important;
}
.Raffleticket__rowText {
  font-family: Montserrat !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  text-align: left !important;
  color: #ffffff !important;
}
.Raffleticket__rowText1 {
  font-family: Montserrat !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: left !important;
  color: #ffffff !important;
}
.Raffleticket__rowText2 {
  font-family: Montserrat !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 21px !important;
  text-align: left !important;
  letter-spacing: -0.001em !important;
  color: #ffffff !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/RaffleTicket/RaffleTicket.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;AACf;AACA;EACE,2CAA2C;EAC3C,kCAAkC;EAClC,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,gDAAgD;EAChD,2BAA2B;EAC3B,2BAA2B;EAC3B,qCAAqC;AACvC;AACA;EACE,kCAAkC;EAClC,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,2BAA2B;EAC3B,yBAAyB;AAC3B;AACA;EACE,kCAAkC;EAClC,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,2BAA2B;EAC3B,yBAAyB;AAC3B;AACA;EACE,kCAAkC;EAClC,0BAA0B;EAC1B,2BAA2B;EAC3B,4BAA4B;EAC5B,2BAA2B;EAC3B,mCAAmC;EACnC,yBAAyB;AAC3B","sourcesContent":[".Raffleticket__main {\n  display: flex;\n  flex-direction: column;\n  background-color: #000000;\n  padding-top: 50px;\n  height: 100vh;\n}\n.Raffleticket__tableheadtext {\n  border-bottom: 1px solid #121212 !important;\n  font-family: Montserrat !important;\n  font-size: 14px !important;\n  font-weight: 500 !important;\n  line-height: 15px !important;\n  letter-spacing: 0.10000000149011612px !important;\n  text-align: left !important;\n  color: #ffffff80 !important;\n  padding: 0px 20px 20px 0px !important;\n}\n.Raffleticket__rowText {\n  font-family: Montserrat !important;\n  font-size: 16px !important;\n  font-weight: 600 !important;\n  line-height: 24px !important;\n  text-align: left !important;\n  color: #ffffff !important;\n}\n.Raffleticket__rowText1 {\n  font-family: Montserrat !important;\n  font-size: 14px !important;\n  font-weight: 400 !important;\n  line-height: 21px !important;\n  text-align: left !important;\n  color: #ffffff !important;\n}\n.Raffleticket__rowText2 {\n  font-family: Montserrat !important;\n  font-size: 14px !important;\n  font-weight: 500 !important;\n  line-height: 21px !important;\n  text-align: left !important;\n  letter-spacing: -0.001em !important;\n  color: #ffffff !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
