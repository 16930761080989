import "./ShopPoints.css";
import * as React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import { getShopRaffle, updateRaffle } from "../../apis/tasks";
const ShopPoints = () => {
  const rows1 = [
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      amount: "300,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      amount: "300,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      amount: "300,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      amount: "300,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      amount: "300,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      amount: "300,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      amount: "300,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      amount: "300,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      amount: "300,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      amount: "300,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
    {
      image: "/Images/redeeemrequest_prof.svg", // Sample image URL
      username: "Orbitz Community",
      wallet: "12345",
      chain: "100,000",
      amount: "300,000",
      action: "cancel",
      // Options: 'check', 'cancel'
    },
  ];
  const [rows, setRows] = useState(rows1);
  const [datas, setDatas] = useState();
  const [updateData, setUpdateData] = useState();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentData, setCurrentData] = useState();

  const handleCheckbox = (index) => {
    const updatedRows = rows.map((row, i) => (i === index ? { ...row, action: row.action === "check" ? "cancel" : "check" } : row));
    setRows(updatedRows);
  };

  async function getData() {
    if (startDate && endDate) {
      console.log("It trigereddd ============");
      const filteredData = currentData.filter((item) => {
        const createdAtDate = new Date(item.createdAt).toISOString().split("T")[0];
        return createdAtDate >= startDate && createdAtDate <= endDate;
      });
      setDatas(filteredData);
    } else {
      const res = await getShopRaffle();
      console.log("this is response", res);
      setDatas(res?.data);
      setCurrentData(res?.data);
    }
  }

  useEffect(() => {
    getData();
  }, [updateData, endDate, startDate]);

  async function approvveTranscation(reddem) {
    try {
      const data = {
        reddemId: reddem, // Example value for reddemId
        status: true, // Example value for status
      };
      const res = await updateRaffle(data);
      console.log(res);
      setUpdateData(res);
    } catch (error) {
      console.log(error);
    }
  }
  async function decline(reddem) {
    try {
      const data = {
        reddemId: reddem, // Example value for reddemId
        status: false, // Example value for status
      };
      const res = await updateRaffle(data);
      console.log(res);
      setUpdateData(res);
    } catch (error) {
      console.log(error);
    }
  }

  function formatDateTime(isoDate) {
    const date = new Date(isoDate);

    // Format date as MM/DD/YYYY
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    // Format time as HH:MM:SS AM/PM
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // AM/PM format
    });

    return `${formattedDate} ${formattedTime}`;
  }

  const handleStartDateChange = (event) => {
    const rawDate = event.target.value;
    const formattedDate = new Date(rawDate).toISOString().split("T")[0];
    setStartDate(formattedDate);
  };

  const handleEndDateChange = (event) => {
    const rawDate = event.target.value;
    const formattedDate = new Date(rawDate).toISOString().split("T")[0];
    setEndDate(formattedDate);
  };

  const clearDates = () => {
    setStartDate("");
    setEndDate("");
    setDatas(currentData);
  };

  // Function to convert data to CSV and download
  const downloadCSV = () => {
    if (!datas || datas.length === 0) {
      alert("No data to export");
      return;
    }

    // Define table headers
    const headers = ["User Image", "Telegram Username", "Points", "Shop Points", "Customer ID", "Created Date", "Approved"];

    // Convert data rows into CSV format
    const rows = datas
      .filter((row) => row?.redeemType === "shop") // Filter only relevant rows
      .map((row) => [
        row?.image || "N/A",
        row?.usersId?.userName || "N/A",
        row?.bePoints || "N/A",
        row?.againstPoint || "N/A",
        row?.usersId?.beUserId || "N/A",
        row?.createdAt ? new Date(row?.createdAt).toISOString().split("T")[0] : "N/A",
        row?.approved ? "Yes" : "No",
      ]);

    // Combine headers and rows into CSV string
    const csvContent = [headers, ...rows]
      .map((e) => e.join(",")) // Join each row with commas
      .join("\n"); // Join all rows with newlines

    // Create a Blob for the CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a download link and trigger the download
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "shopPointsData.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="ShopPoints__main">
      <div className="Btn_Export_Section">
        <div className="Export_Input_Section">
          <input
            type="date"
            id="startInput_Tag"
            onClick={(e) => e.target.showPicker()}
            value={startDate}
            min={new Date().toISOString().split("T")[0]}
            onChange={handleStartDateChange}
            placeholder="Start Date"
          />
        </div>
        <div className="Export_Input_Section">
          <input
            id="endInput_Tag"
            type="date"
            onClick={(e) => e.target.showPicker()}
            value={endDate}
            min={startDate}
            onChange={handleEndDateChange}
            disabled={!startDate}
            placeholder="End Date"
          />
        </div>
        {startDate && (
          <div onClick={clearDates} className="ClearDate_Btn">
            x
          </div>
        )}
        <button className="Download_CSV_BTN" onClick={downloadCSV}>
          Export CSV
        </button>
      </div>
      <TableContainer
        component={Paper}
        className="tableContainer__"
        sx={{
          backgroundColor: "#090909",
          border: "2px solid #121212",
          borderRadius: "10px",
          padding: "30px",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="custom table">
          <TableHead>
            <TableRow>
              <TableCell className="ShopPoints__tableheadtext">User Image</TableCell>
              <TableCell className="ShopPoints__tableheadtext">Telegram Username</TableCell>
              <TableCell className="ShopPoints__tableheadtext">Date</TableCell>
              <TableCell className="ShopPoints__tableheadtext">Points</TableCell>
              <TableCell className="ShopPoints__tableheadtext">Shop Points</TableCell>
              <TableCell className="Raffleticket__tableheadtext">Customer ID</TableCell>
              <TableCell className="ShopPoints__tableheadtext">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {datas?.map((row, index) => {
              if (row?.redeemType == "shop")
                return (
                  <TableRow key={index}>
                    <TableCell style={{ borderBottom: "1px solid #121212" }}>
                      <Avatar alt={row.username} src={row?.usersId?.profilePic} />
                    </TableCell>
                    <TableCell style={{ borderBottom: "1px solid #121212" }} className="ShopPoints__rowText">
                      {row?.usersId?.userName}
                    </TableCell>
                    <TableCell style={{ borderBottom: "1px solid #121212" }} className="ShopPoints__rowText1">
                      {formatDateTime(row?.createdAt)}
                    </TableCell>
                    <TableCell style={{ borderBottom: "1px solid #121212" }} className="ShopPoints__rowText1">
                      {row?.bePoints}
                    </TableCell>
                    <TableCell style={{ borderBottom: "1px solid #121212" }} className="ShopPoints__rowText1">
                      {row.againstPoint}
                    </TableCell>
                    <TableCell style={{ borderBottom: "1px solid #121212" }} className="Raffleticket__rowText1">
                      {row?.usersId?.beUserId}
                    </TableCell>
                    {row?.approved ? (
                      <TableCell
                        style={{
                          borderBottom: "1px solid #121212",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <img src="/Images/tick.svg" alt="Tick" />
                      </TableCell>
                    ) : row?.approved == false ? (
                      <TableCell
                        style={{
                          borderBottom: "1px solid #121212",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <img src="/Images/cancel.svg" alt="Cancel" />
                      </TableCell>
                    ) : (
                      <TableCell
                        style={{
                          borderBottom: "1px solid #121212",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <img
                          onClick={() => {
                            approvveTranscation(row?._id);
                          }}
                          src="/Images/tick.svg"
                          alt="Tick"
                        />

                        <img
                          onClick={() => {
                            decline(row?._id);
                          }}
                          src="/Images/cancel.svg"
                          alt="Cancel"
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ShopPoints;
